import React, { useState } from 'react'
import logo from '../../assets/pics/logoMain.svg'

import chevron_down from "../../assets/pics/chevron_down.svg";
import { Cookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';

import profile from "../../assets/pics/profile.svg";

const Navbar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const handleLogout = () => {
        const cookies = new Cookies();

        // Remove the token from cookies
        cookies.remove("token", { path: "/" });

        // Remove the accessToken and vendorId from localStorage
        localStorage.removeItem("accessToken");
        localStorage.removeItem("vendorId");

        // Redirect to the home page or login page
        navigate("/");
    };
    return (
        <>
            <div className="flex justify-between w-full items-center lg:px-16 px-5 py-4">
                <img src={logo} alt="" className="" />
                <div className="flex gap-5 items-center">
                    <h2 className="lg:text-3xl lg:block hidden font-bold text-primary">FMV Admin Portal</h2>
                    <div className='flex items-center gap-2'>
                        <div className="flex items-center space-x-2">
                            <img src={profile} alt="User" className="h-8 w-8 rounded-full" />
                            <div className="flex flex-col">
                                <span className="text-sm font-semibold">Admin</span>
                            </div>
                        </div>
                        <img
                            src={chevron_down}
                            alt="Dropdown"
                            className="h-5 w-5 cursor-pointer"
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                        />
                        {dropdownOpen && (
                            <div className="absolute right-5 mt-20 py-2 w-24 bg-white border rounded shadow-lg">

                                <button
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full text-left"
                                    onClick={handleLogout}
                                >
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar