/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useMemo, useState } from 'react';
import Navbar from '../Navbar';
import DynamicSVG from './dynmaic-svg'; // Assuming the correct spelling
import {
	MdOutlineArrowLeft,
	MdOutlineArrowRight,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { DeleteBtn, Filter, Seach, SortBtn } from '../../../svgs';
import {
	useQueryClient,
	useMutation,
	useQuery,
} from '@tanstack/react-query'; // Ensure you're importing from the correct package
import {
	approveItem,
	deleteItem,
	getVenues,
	rejectItem,
} from '../../../api/getVenue';
import toast, { LoaderIcon } from 'react-hot-toast';
import Modal from '../../shared/custom-modal/custom-modal';
import { MenuOpen } from '@mui/icons-material';
import { formatDate } from '../../../utils/format-date';

const rejectionReasons = [
	'Incomplete Application ',
	'Photos and Videos are not clear',
	'Inaccurate/Missing Information',
	'Other',
];

const filterValue = ['All', 'location', 'date', 'status', 'category'];

const VenuesTable = () => {
	const [filter, setFilter] = useState('All');
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [searchQuery, setSearchQuery] = useState('');
	const [sortConfig, setSortConfig] = useState({
		key: 'date',
		direction: 'ascending',
	});
	const [selectedItem, setSelectedItem] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [rejectionReason, setRejectionReason] = useState('');
	const [selectedReasons, setSelectedReasons] = useState([]);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [dropdownFilter, setDropdownFIlter] = useState(false);
	// const [filterDropdown, setFilterDropdown] = useState(null);
	const [openMenu, setMenuOpen] = useState(false);
	const [isApproving, setIsApproving] = useState(false);
	const [isRejecting, setIsRejecting] = useState(false);

	const [selectedDate, setSelectedDate] = useState(null);

	const { data, error, isLoading, refetch } = useQuery({
		queryKey: ['venueData', currentPage, itemsPerPage],
		queryFn: async () => {
			return await getVenues(currentPage, itemsPerPage);
		},
		refetchOnWindowFocus: false,
	});

	const { forms = [] } = data || {};
	// console.log('ddd    🤦‍♂️', data);
	const filteredData = (data || []).filter((item) => {
		// Search query for date filter
		if (filter === 'date') {
			if (!selectedDate) return true;
			const itemDate = new Date(item.createdAt || item.updatedAt); // Check both createdAt and updatedAt
			return itemDate.toDateString() === selectedDate.toDateString();
		}

		const searchLower = searchQuery.toLowerCase();
		const deepSearch = (value) => {
			if (typeof value === 'string') {
				return value.toLowerCase().includes(searchLower);
			} else if (typeof value === 'object' && value !== null) {
				if (Array.isArray(value)) {
					return value.some((arrayItem) => deepSearch(arrayItem));
				} else {
					return Object.values(value).some((subValue) =>
						deepSearch(subValue)
					);
				}
			}
			return false;
		};

		// Perform filtering based on the filter type
		if (filter === 'All') {
			return Object.keys(item).some((key) => deepSearch(item[key]));
		} else if (filter === 'location') {
			return (
				deepSearch(item.location?.city) ||
				deepSearch(item.location?.area)
			);
		} else if (filter === 'status') {
			return deepSearch(item.itemStatus);
		} else if (filter === 'category') {
			return deepSearch(item.category);
		}
		return false;
	});

	// Function to handle sorting
	const requestSort = (key) => {
		let direction = 'ascending';
		if (
			sortConfig.key === key &&
			sortConfig.direction === 'ascending'
		) {
			direction = 'descending';
		}
		setSortConfig({ key, direction });
	};

	// Function to get sorted data
	const sortedData = useMemo(() => {
		let sortableData = [...filteredData];
		if (sortConfig.key !== '') {
			sortableData.sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableData;
	}, [filteredData, sortConfig.direction, sortConfig.key]);
	const queryClient = useQueryClient();

	const totalPages = Math.ceil(filteredData.length / itemsPerPage);

	// Apply pagination by slicing the filtered data
	const paginatedData = sortedData?.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	// Mutation for deleting an item
	const mutation = useMutation({
		mutationFn: deleteItem,
		onSuccess: () => {
			// Invalidate and refetch queries after successful delete
			queryClient.invalidateQueries(['venueData']);
		},
		onError: (error) => {
			console.error('Error deleting item:', error.message);
		},
	});

	const handleDelete = async (category, id) => {
		await mutation.mutateAsync({ category, id });
		refetch();
		toast.success('Item deleted successfully');
	};

	const approveMutation = useMutation({
		mutationFn: approveItem,
		onSuccess: () => {
			queryClient.invalidateQueries(['venueData']);
			toast.success('Item approved successfully');
		},
		onError: (error) => {
			console.error('Error approving item:', error.message);
		},
	});

	const rejectMutation = useMutation({
		mutationFn: rejectItem,
		onSuccess: () => {
			queryClient.invalidateQueries(['venueData']);
			setModalOpen(false);
			toast.success('Item rejected successfully');
		},
		onError: (error) => {
			console.error('Error rejecting item:', error.message);
		},
	});

	const handleApprove = async () => {
		if (!selectedItem) {
			return;
		}

		try {
			setIsApproving(true);
			await approveMutation.mutateAsync({
				category: selectedItem?.category,
				id: selectedItem._id,
				email: selectedItem?.vendorEmail,
				name:
					selectedItem?.category === 'Hotel'
						? selectedItem?.overview?.basicDetails?.hotelName
						: selectedItem?.basicDetails?.venueName,
			});
		} catch (error) {
			console.error('Error approving item:', error.message);
			toast.error('Error approving item');
		} finally {
			setIsApproving(false);
		}
	};
	const handleReject = async () => {
		const updatedReason = [...selectedReasons]; // Create a copy of the selectedReasons array
		if (rejectionReason) {
			updatedReason.push(rejectionReason); // Add the rejectionReason to the array
		}

		if (updatedReason.length === 0) {
			toast.error('Please select at least one reason for rejection');
			return;
		}

		try {
			setIsRejecting(true);
			await rejectMutation.mutateAsync({
				category: selectedItem.category,
				id: selectedItem._id,
				reasons: updatedReason, // Send the updated array of reasons
				email: selectedItem?.vendorEmail,
				name:
					selectedItem?.category === 'Hotel'
						? selectedItem?.overview?.basicDetails?.hotelName
						: selectedItem?.basicDetails?.venueName,
			});
			setModalOpen(false);
		} catch (error) {
			console.error('Error rejecting item:', error.message);
			toast.error('Error rejecting item');
		} finally {
			setIsRejecting(false);
		}
	};

	return (
		<>
			{(isRejecting || isApproving) && (
				<div className='flex fixed top-0 left-0 z-50  justify-center items-center min-w-full min-h-screen bg-black opacity-30 '>
					<LoaderIcon />
				</div>
			)}
			<Navbar />

			<div className='p-8 min-h-screen'>
				<div className='flex justify-between items-center mb-6'>
					<div className='flex items-center justify-start space-x-4 lg:px-5 lg:w-2/5'>
						<div className='relative '>
							<button
								className='bg-primary flex gap-2 items-center text-white rounded-lg py-3 px-5 border'
								onClick={() => setDropdownFIlter(!dropdownFilter)}
							>
								{' '}
								<Filter /> {filter === 'All' ? 'Filter' : filter}{' '}
							</button>
							{dropdownFilter && (
								<div className='absolute right-0 z-30 mt-2 w-32  origin-top-right bg-white border border-gray-200 rounded-md shadow-lg ring-1  flex flex-col gap-2 focus:outline-none'>
									{filterValue.map((item, i) => (
										<button
											key={i}
											onClick={() => {
												setFilter(item);
												// setDebouncedSearchTerm(true)
												setDropdownFIlter(false);
											}}
											className='py-3 px-4 text-left hover:bg-gray-100'
										>
											{item}
										</button>
									))}
								</div>
							)}
						</div>
						{filter === 'date' ? (
							<input
								type='date'
								id='date-picker'
								className='relative border-borderShade border rounded-lg text-secondary py-3 lg:w-1/2 w-full px-2'
								value={
									selectedDate
										? selectedDate?.toISOString().split('T')[0]
										: ''
								}
								onChange={(e) =>
									setSelectedDate(new Date(e.target.value))
								}
							/>
						) : (
							<div className='relative border-borderShade border rounded-lg text-secondary py-3 lg:w-1/2 w-full'>
								<span className='absolute inset-y-0 grid w-10 place-content-center'>
									<button
										type='button'
										className='text-gray-600 hover:text-gray-700'
									>
										<span className='sr-only'>Search</span>
										<Seach />
									</button>
								</span>
								<input
									type='text'
									id='Search'
									placeholder='Search for...'
									className='w-full rounded-md focus:outline-none ps-16 text-sm'
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
								/>
							</div>
						)}
					</div>
				</div>
				{isLoading ? (
					<div className='flex justify-center items-center w-full py-20'>
						{/* <LoaderIcon /> */}
						<span className='text-primary text-2xl font-semibold text-center animate-bounce py-5'>
							Loading...
						</span>
					</div>
				) : (
					<>
						<div className='overflow-x-auto px-5 lg:flex hidden flex-col gap-5 pb-16'>
							<table className='min-w-full bg-white rounded-lg w-full flex flex-col gap-5 justify-between'>
								<div className='w-full'>
									<tr className='text-sm text-left text-offGray font-[400] flex gap-5 items-start overflow-x-auto justify-between'>
										<td className='py-3 px-4'></td>
										<td className='py-3 px-4'>Status</td>
										<td className='py-3 px-4'>
											<button
												className='flex gap-2 items-center'
												onClick={() => requestSort('date')}
											>
												Date Submitted <SortBtn />
											</button>
										</td>

										<td className='py-3 '>
											<button
												className='flex gap-2 items-center'
												onClick={() => requestSort('category')}
											>
												Category <SortBtn />
											</button>
										</td>
										<td className='py-3 px-4'>Name of Premises</td>
										<td className='py-3 px-4'>View Doc</td>

										<td className='py-3 px-4'>Preview</td>

										<td className='py-3 px-4'>Location (City)</td>
										<td className='py-3 px-4 lg:w-[15%]'>Email</td>

										<td className='py-3 px-4'>Approve</td>
										{/* <td className="py-3 px-4">Delete</td> */}
									</tr>
								</div>
								<tbody className='w-full'>
									{paginatedData &&
										paginatedData.map((item, index) => (
											<tr
												key={index}
												className='my-2 text-offGray w-full flex gap-5 items-center justify-between relative'
											>
												<td className='py-3 px-4 lg:w-20'>
													<DynamicSVG
														index={index}
														currentPage={currentPage}
														itemsPerPage={itemsPerPage}
													/>
												</td>
												<div
													key={index}
													className='border border-[#E9EFF4] text-start rounded-lg w-full flex  gap-5 items-center overflow-x-auto justify-between '
												>
													<td
														className={`py-3 lg:w-[10%] px-4 
																				${
																					item.itemStatus ===
																					'Approved'
																						? 'text-green-500' // Approved and Completed
																						: item.itemStatus ===
																						  'ReSubmitting'
																						? 'text-yellow-500' // Rejected and Completed (ReSubmitting)
																						: item.itemStatus ===
																						  'Rejected'
																						? 'text-red-400' // Rejected and Pending
																						: item.itemStatus ===
																						  'pending'
																						? 'text-blue-500' // Pending and Completed
																						: item.itemStatus ===
																						  'Edit Pending'
																						? 'text-yellow-400'
																						: item.itemStatus ===
																						  'unpublish'
																						? 'text-gray-500'
																						: 'text-blue-500' // Default case
																				}`}
													>
														{
															item.itemStatus === 'Approved'
																? 'Approved' // Approved and Completed
																: item.itemStatus === 'ReSubmitting'
																? 'ReSubmitting' // Rejected and Completed (ReSubmitting)
																: item.itemStatus === 'Rejected'
																? 'Rejected' // Rejected and Pending
																: item.itemStatus === 'Edit Pending'
																? 'Edit Pending' // Pending and Completed
																: item.itemStatus === 'unpublish'
																? 'Unpublished' // Pending and Completed
																: 'Pending' // Default case
														}
													</td>

													<td className='py-3 lg:w-[10%] px-4'>
														{' '}
														{item?.updatedAt
															? formatDate(item.updatedAt)
															: formatDate(item.createdAt)}
													</td>
													<td className='py-3 lg:w-[8%] px-4'>
														{item?.category}
													</td>
													{/* <td className="py-3 lg:w-[8%] px-4">...{item._id.slice(item._id, 7)}</td> */}
													<td className='py-3 lg:w-[12%] px-4'>
														{item.category === 'Hotel'
															? item.overview?.basicDetails?.hotelName.slice(
																	0,
																	20
															  )
															: item.basicDetails?.venueName?.slice(
																	0,
																	20
															  )}
													</td>

													<td className='py-3 lg:w-[12%] px-4'>
														<Link
															to={`/doc-preview/${item.category}/${item._id}`}
															className='bg-blue-400 text-white py-2 shadow-lg px-4 text-sm rounded-lg'
														>
															View Doc
														</Link>
													</td>
													<td className='py-3 lg:w-[8%] px-4'>
														<Link
															to={`/${
																item.category === 'Hotel'
																	? 'hotel-preview'
																	: 'venue-preview'
															}/${item.category}/${item._id}`}
															className='bg-primary text-white py-2 shadow-lg px-2 text-sm rounded-lg'
														>
															Preview
														</Link>
													</td>
													<td className='py-3 lg:w-[10%] px-4'>
														{item?.category === 'Hotel' ? (
															<>
																{item.overview?.locationDetails?.city}{' '}
																{item.overview?.locationDetails?.area}
															</>
														) : (
															<>
																{item.location?.city}{' '}
																{item.location?.area}
															</>
														)}
													</td>

													<td className='py-3 lg:w-[15%] px-1 text-start'>
														{item?.vendorEmail}
													</td>

													<td className='py-3 px-4 lg:w-[10%] flex gap-4 justify-end'>
														<div className='inline-block text-left '>
															<button
																onClick={() => {
																	setDropdownOpen(index);
																	setMenuOpen(!openMenu);
																}}
																className='px-2 py-1 text-sm text-primary rounded-lg'
															>
																<MenuOpen />
															</button>
															{openMenu && dropdownOpen === index && (
																<div className='absolute right-7 mt-1 w-32 z-50 origin-top-right bg-white border border-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 flex flex-col'>
																	<button
																		onClick={() => {
																			setSelectedItem(item);
																			handleApprove();
																			setDropdownOpen(null);
																		}}
																		className='block px-4 py-2 text-sm text-green-500 hover:bg-gray-100 rounded-lg'
																	>
																		Approve
																	</button>
																	<button
																		onClick={() => {
																			setSelectedItem(item);
																			setModalOpen(true);
																			setDropdownOpen(null);
																		}}
																		className='block px-4 py-2 text-sm text-red-500 hover:bg-gray-100 rounded-lg'
																	>
																		Reject
																	</button>
																</div>
															)}
														</div>
													</td>

													{/* <td className="py-3 pe-5 text-center">
                                            <button
                                                onClick={() => handleDelete(item.category, item._id)} className="text-red-500">
                                                <DeleteBtn />
                                            </button>
                                        </td> */}
												</div>
											</tr>
										))}
								</tbody>
							</table>
						</div>
						<div className='flex flex-col lg:hidden gap-5 w-full '>
							{paginatedData &&
								paginatedData.map((item) => (
									<div
										key={item?._id}
										className='w-full border px-4 relative  rounded-lg flex flex-wrap gap-2 py-3'
									>
										<div className='flex flex-col gap-1 w-[48%]'>
											<div className='text-black font-medium'>
												Status:
											</div>
											<p
												className={` ${
													item.itemStatus === 'Approved'
														? 'text-green-500'
														: item.itemStatus === 'Rejected'
														? 'text-red-400'
														: 'text-blue-500'
												}`}
											>
												{item?.itemStatus
													? item.itemStatus
													: 'Pending'}
											</p>
										</div>
										<div className='flex flex-col gap-1 w-[48%]'>
											<div className='text-black font-medium'>
												Date:
											</div>
											<p className='text-secondary'>
												{item?.updatedAt
													? formatDate(item.updatedAt)
													: formatDate(item.createdAt)}
											</p>
										</div>
										<div className='flex flex-col gap-1 w-[48%]'>
											<div className='text-black font-medium'>
												Category:
											</div>
											<p className='text-secondary'>
												{item?.category}
											</p>
										</div>
										<div className='flex flex-col gap-1 w-[48%]'>
											<div className='text-black font-medium'>
												Name of Premises:
											</div>
											<p className='text-secondary'>
												{item.category === 'Hotel'
													? item.overview?.basicDetails?.hotelName
													: item.basicDetails?.venueName}
											</p>
										</div>
										<div className='flex flex-col gap-1 w-full'>
											<div className='text-black font-medium break-words'>
												Location:
											</div>
											<p className='text-secondary break-words'>
												{item.location?.city} {item.location?.area}
											</p>
										</div>
										<div className='flex flex-col gap-1 w-full'>
											<div className='text-black font-medium break-words'>
												Email:
											</div>
											<p className='text-secondary break-words'>
												{item?.vendorEmail}
											</p>
										</div>
										<div className='flex items-center  gap-2 mt-2 w-full justify-between'>
											<Link
												to={`/doc-preview/${item.category}/${item._id}`}
												className='bg-blue-400 text-white py-2 shadow-lg px-4 text-sm rounded-lg'
											>
												View Doc
											</Link>
											<Link
												to={`/${
													item.category === 'Hotel'
														? 'hotel-preview'
														: 'venue-preview'
												}/${item.category}/${item._id}`}
												className='bg-primary text-white py-2 shadow-lg px-2 text-sm rounded-lg'
											>
												Preview
											</Link>
										</div>

										<div className='py-3 px-4 w-[20%] absolute right-0 top-0 flex gap-4 justify-end'>
											<div className='inline-block text-left '>
												<button
													onClick={() => {
														setDropdownOpen(item?._id);
														setMenuOpen(!openMenu);
													}}
													className='px-2 py-1 text-sm text-primary rounded-lg'
												>
													<MenuOpen />
												</button>
												{openMenu && dropdownOpen === item?._id && (
													<div className='absolute right-7 mt-1 w-32 z-50 origin-top-right bg-white border border-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 flex flex-col'>
														<button
															onClick={() => {
																setSelectedItem(item);
																handleApprove();
																setDropdownOpen(null);
															}}
															className='block px-4 py-2 text-sm text-green-500 hover:bg-gray-100 rounded-lg'
														>
															Approve
														</button>
														<button
															onClick={() => {
																setSelectedItem(item);
																setModalOpen(true);
																setDropdownOpen(null);
															}}
															className='block px-4 py-2 text-sm text-red-500 hover:bg-gray-100 rounded-lg'
														>
															Reject
														</button>
													</div>
												)}
											</div>
										</div>
									</div>
								))}
						</div>
					</>
				)}
				<div className='flex justify-center items-center relative bottom-0 gap-5 '>
					<span className='text-black'>Page</span>
					<div className='flex items-center space-x-2'>
						<button
							onClick={() => setCurrentPage((prev) => prev - 1)}
							disabled={currentPage === 1}
							className={`disabled:opacity-50 ${
								currentPage === 1 ? 'cursor-not-allowed' : ''
							}`}
						>
							<MdOutlineArrowLeft className='text-primary text-3xl' />
						</button>
						<div className='min-w-[24px] h-[24px] flex bg-primary text-white justify-center items-center rounded-full'>
							{currentPage}
						</div>
						<button
							onClick={() => setCurrentPage((prev) => prev + 1)}
							disabled={currentPage === totalPages}
							className={`py-2 px-4 disabled:opacity-50 ${
								currentPage === totalPages ? 'cursor-not-allowed' : ''
							}`}
						>
							<MdOutlineArrowRight className='text-primary text-3xl' />
						</button>
					</div>
					<select
						value={itemsPerPage}
						onChange={(e) => {
							setCurrentPage(1);
							setItemsPerPage(parseInt(e.target.value, 10));
						}}
						className='py-2 bg-white border-b border-blue-200 rounded'
					>
						<option value={10}>10</option>
						<option value={20}>20</option>
						<option value={30}>30</option>
					</select>
				</div>

				<Modal onClose={() => setModalOpen(false)} isOpen={modalOpen}>
					<h2 className='text-lg font-semibold mb-4'>
						Rejection Reason
					</h2>
					<div className='space-y-2'>
						{rejectionReasons.map((reason) => (
							<label key={reason} className='block'>
								<input
									type='checkbox'
									value={reason}
									checked={selectedReasons.includes(reason)}
									onChange={(e) => {
										const value = e.target.value;
										setSelectedReasons((prev) =>
											prev.includes(value)
												? prev.filter((item) => item !== value)
												: [...prev, value]
										);
									}}
									className='mr-2'
								/>
								{reason}
							</label>
						))}
						<textarea
							value={rejectionReason}
							onChange={(e) => setRejectionReason(e.target.value)}
							rows={4}
							className='w-full border border-gray-300 rounded-lg p-2'
							placeholder='Provide a reason for rejection (optional)'
						/>
					</div>
					<div className='flex gap-4 mt-4'>
						<button
							onClick={handleReject}
							className='px-4 py-2 bg-red-500 text-white rounded-lg'
						>
							Submit Rejection
						</button>
						<button
							onClick={() => setModalOpen(false)}
							className='px-4 py-2 bg-gray-300 rounded-lg'
						>
							Cancel
						</button>
					</div>
				</Modal>
			</div>
		</>
	);
};

export default VenuesTable;
