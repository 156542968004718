import React from 'react';
import { Link } from 'react-router-dom';
import p1 from '../../assets/icons/capacity/1.svg'
import p2 from '../../assets/icons/capacity/2.svg'
import p3 from '../../assets/icons/capacity/3.svg'
import p4 from '../../assets/icons/capacity/4.svg'
import p5 from '../../assets/icons/capacity/5.svg'
import p6 from '../../assets/icons/capacity/6.svg'
import p7 from '../../assets/icons/capacity/7.svg'
const CapacityLayout = ({ data }) => {
    // Define icons for each layout type
    const icons = {
        standing: p1,
        sitting: p2,
        dining: p3,
        theatre: p4,
        boardroom: p5,
        cabaret: p6,
        ushaped: p7,
    };

    // Map over the capacity data based on the formData
    const capacityData = [
        { icon: p1, title: 'Standing', description: data.standing ? `up to ${data.standing} People` : 'Not available' },
        { icon: p2, title: 'Sitting', description: data.sitting ? `up to ${data.sitting} People` : 'Not available' },
        { icon: p3, title: 'Dining', description: data.additionalLayout.dining.enabled ? `up to ${data.additionalLayout.dining.value} People` : 'Not available' },
        { icon: p4, title: 'Theatre', description: data.additionalLayout.theatre.enabled ? `up to ${data.additionalLayout.theatre.value} People` : 'Not available' },
        { icon: p5, title: 'Boardroom', description: data.additionalLayout.boardroom.enabled ? `up to ${data.additionalLayout.boardroom.value} People` : 'Not available' },
        { icon: p6, title: 'Cabaret', description: data.additionalLayout.cabaret.enabled ? `up to ${data.additionalLayout.cabaret.value} People` : 'Not available' },
        { icon: p7, title: 'U-shaped', description: data.additionalLayout.ushaped.enabled ? `up to ${data.additionalLayout.ushaped.value} People` : 'Not available' },
    ];

    return (
        <div className="rounded-lg mb-8 lg:w-3/4 w-full px-5 mx-auto py-10">
            <h2 className="text-2xl font-bold mb-4">Capacity & Layout</h2>

            <div className="flex justify-between flex-wrap">
                <div className="mb-4">
                    <span className="text-lg font-semibold">Capacity</span>
                    <Link to="#" className="text-red-500 underline ml-2">View Floor Plan</Link>
                </div>
                <div className="grid lg:w-3/4 w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    {capacityData.map((item, index) => (
                        <div key={index} className="flex items-center p-4 rounded-lg">
                            <div className="text-3xl flex justify-center items-center mr-4 p-3 bg-gray-100">
                                <img src={item.icon} alt={item.title} />
                            </div>
                            <div>
                                <div className="text-lg font-semibold">{item.title}</div>
                                <div className="text-gray-600">{item.description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CapacityLayout;
