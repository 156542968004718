import React from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { CircularProgress, Box, Typography } from "@mui/material"; // Importing Material-UI components for loading spinner

const mapContainerStyle = {
    width: "100%",
    height: "400px", // Adjust height as needed
};

const defaultCenter = {
    lat: 25.276987,
    lng: 55.296249,
};

const MapContainer = ({ lat, lng, onLocationChange }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg', // Use environment variable for API key
    });

    if (loadError) return <div>Error loading maps</div>;

    const location = lat && lng ? { lat, lng } : defaultCenter;

    const handleMarkerDragEnd = (e) => {
        const newLat = e.latLng.lat();
        const newLng = e.latLng.lng();
        onLocationChange(newLat, newLng);
    };

    return (
        <div>
            <div className="flex justify-between items-center w-full mb-2">
                <p className="text-sm text-secondary">Map Location</p>
                {/* <button className="text-primary text-base flex underline font-medium">
                    Pin Locations
                </button> */}
            </div>
            {isLoaded ? (
                <GoogleMap mapContainerStyle={mapContainerStyle} zoom={15} center={location}>
                    <Marker position={location} draggable={true} onDragEnd={handleMarkerDragEnd} />
                </GoogleMap>
            ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                    <CircularProgress />
                    <Typography variant="body1" marginLeft={2}>
                        Loading Map...
                    </Typography>
                </Box>
            )}
        </div>
    );
};

export default MapContainer;