const EventType = ({ data }) => {
  // console.log('Raw Data:', data.eventType);

  const eventTypes = [
    "Art Spaces",
    "Conference/Seminars",
    "Corporate Events",
    "Cultural",
    "Dining",
    "Filming, Photography & Media",
    "Milestone Birthdays",
    "Performances & Exhibitions",
    "Special Big Events",
    "Tea Party/Showers",
    "Teen/Children",
    "Weddings",
  ];

  // Helper function to render categories and their items
  const renderCategory = (title, items) => (
    <>
      {Array.isArray(items) && eventTypes?.includes(title) ? (
        <div className="mb-4 flex gap-5 w-full lg:w-full" key={title}>
          <h3 className="text-xl font-semibold lg:w-1/2 mb-2">
            {Array.isArray(items) && title}
          </h3>
          <div className="flex flex-wrap lg:w-3/4 gap-4">
            {(Array.isArray(items) ? items : []).map((item, index) => (
              <span
                key={index}
                className="px-4 py-2 border rounded-full border-lightGray text-lightGray cursor-pointer hover:bg-gray-200"
              >
                {item}
              </span>
            ))}
            {/* {(!Array.isArray(items) || items.length === 0) && (
                    <p className="text-gray-500">No items available</p>
                )} */}
          </div>
        </div>
      ) : null}
    </>
  );

  return (
    <div className="py-5 lg:w-3/4 w-full flex flex-wrap gap-5 px-5 border-t mx-auto">
      <h2 className="text-2xl font-bold mb-4 w-full">Event Type</h2>
      <div className="flex flex-wrap gap-4 w-full">
        {Object.keys(data.eventType).map((category) =>
          renderCategory(category, data.eventType[category])
        )}
        {/* Uncomment and modify if you have eventVenuesForKids and related data
                {eventVenuesForKids && (
                    <div className="mb-4 w-full">
                        <h3 className="text-xl font-semibold mb-2">Event Venues for Kids</h3>
                        <div className="flex flex-wrap gap-4">
                            {eventVenuesForKidsIncludes.length > 0 ? (
                                eventVenuesForKidsIncludes.map((item, index) => (
                                    <span
                                        key={index}
                                        className="px-4 py-2 border rounded-full border-lightGray text-lightGray cursor-pointer hover:bg-gray-200"
                                    >
                                        {item}
                                    </span>
                                ))
                            ) : (
                                <p className="text-gray-500">No items available</p>
                            )}
                        </div>
                    </div>
                )}
                */}
      </div>
    </div>
  );
};

export default EventType;
