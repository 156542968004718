import React from "react";
import {
  FaCoffee,
  FaChild,
  FaBeer,
  FaVolumeUp,
  FaMusic,
  FaBan,
} from "react-icons/fa";

const Highlights = ({ data }) => {
  const highlightsData = [
    { icon: <FaCoffee />, text: "Refreshments", condition: data?.refreshments },
    {
      icon: <FaChild />,
      text: "Kids section available",
      condition: data?.activitiesForKids,
    },
    {
      icon: <FaBeer />,
      text: "Alcohol/ Liquor License until 10:00 PM",
      condition: data?.alcoholLiquorLicense,
    },
    {
      icon: <FaVolumeUp />,
      text: "PA system / Speakers",
      condition: data?.paSystemSpeakers,
    },
    {
      icon: <FaMusic />,
      text: "Indoor music allowed until 10:00 PM",
      condition: data?.indoorMusicAllowed,
    },
    {
      icon: <FaBan />,
      text: "Shisha",
      condition: data?.shisha,
      disabled: true,
    },
  ];

  return (
    <div className="py-5 lg:w-3/4 mx-auto w-full px-5 rounded-lg">
      <div className="">
        <h2 className="text-2xl font-semibold mb-4">Highlights</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-8 w-full rounded-xl bg-[#FFEBEB]">
          {highlightsData
            .filter((item) => item.condition)
            .map((item, index) => (
              <div key={index} className="flex items-center">
                <span
                  className={`text-2xl mr-4 ${
                    item.disabled ? "text-gray-400" : ""
                  }`}
                >
                  {item.icon}
                </span>
                <span
                  className={`${
                    item.disabled ? "text-gray-400 line-through" : "text-black"
                  }`}
                >
                  {item.text}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Highlights;
