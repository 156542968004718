/* eslint-disable no-sequences */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaMapMarkerAlt, FaRegMap } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { MdOutlineDining } from "react-icons/md";
import { LiaChairSolid } from "react-icons/lia";
import { FiTruck } from "react-icons/fi";
import { Link } from "react-router-dom";

const OverviewSection = ({ data, locationDetails }) => {
  // Overview details pulled dynamically from data
  const overviewDetails = [
    {
      icon: <HiOutlineBuildingOffice2 className="text-5xl mb-2" />,
      label: "Private space",
      value: `Private space`,
    },
    {
      icon: <LiaChairSolid className="text-5xl mb-2" />,
      label: "Sitting",
      value: `Upto ${data?.capacity?.sitting || "N/A"} Sitting`,
    },
    {
      icon: <FaPeopleGroup className="text-5xl mb-2" />,
      label: "Standing",
      value: `Upto ${data?.capacity?.standing || "N/A"} Standing`,
    },
    {
      icon: <MdOutlineDining className="text-5xl mb-2" />,
      label: "Offers catering",
      value: "Offers catering",
    },
    {
      icon: <FiTruck className="text-5xl mb-2" />,
      label: "Outside catering",
      value: "Outside catering allowed",
    },
  ];

  return (
    <div className="lg:w-3/4 w-full px-5 mx-auto">
      <div className="flex flex-col j mb-4">
        <h2 className="text-[32px] font-bold flex gap-4 items-center">
          {data?.basicDetails?.venueName || "Venue Name Missing"}
        </h2>

        <div className="text-gray-600 flex gap-2 items-center mb-4">
          <FaMapMarkerAlt className="inline mr-2" />
          <span className="font-medium underline decoration-2 underline-offset-2">
            {locationDetails?.city && locationDetails?.area
              ? `${locationDetails?.city}, ${locationDetails?.area}`
              : "Location Not Available"}
          </span>
          {data?.venueType?.venueRepresent && (
            <>
              <span className="h-6 w-[2px] bg-[#B0B0B0]"></span>
              <span className="ml-0.5">
                {data?.venueType?.venueRepresent} Hotel
              </span>
            </>
          )}

          <a
            href="#map"
            className="text-blue-500 flex ml-1 font-semibold gap-2 items-center"
          >
            <FaRegMap />{" "}
            <span className="underline underline-offset-2 decoration-2">
              Show on map
            </span>
          </a>
        </div>
      </div>

      <h3 className="text-2xl font-bold py-4">Overview</h3>

      <div className="grid lg:grid-cols-5 grid-cols-2 gap-8 mb-4">
        {overviewDetails.map((detail, index) => (
          <div
            key={index}
            className="flex flex-col px-8 py-5 w-full text-black items-center bg-[#F7F7F7] p-4 rounded-lg shadow-md"
          >
            {detail.icon}
            <p className="text-xl  text-center">{detail.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OverviewSection;
