import React, { useState } from "react";
import { Box, TextField, Typography, InputAdornment } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTheme } from "@mui/material/styles";

const OutlinedLabelledTextField = ({
    id,
    label,
    placeholder,
    type,
    value,
    onChange,
    width,
    error,
    helperText,
    togglefunction,
    showPassword,
    eye, // Add eye as a new parameter
}) => {



    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                mb: 2,
                width: { xs: "100%", md: width } || "100%",
            }}
        >
            <Typography
                variant="body1"
                gutterBottom
                fontWeight="300"
                align="left"
                sx={{
                    fontSize: {
                        xs: "subtitle2.fontSize",
                        md: "body1.fontSize",
                        color: theme.palette.grey.text,
                    },
                }}
            >
                {label}
            </Typography>
            <TextField
                required
                fullWidth
                id={id}
                placeholder={placeholder}
                variant="outlined"
                type={type}
                value={value}
                onChange={onChange}
                error={error}
                helperText={helperText}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: eye ? (
                        <InputAdornment position="end">
                            <div className="cursor-pointer" onClick={() => { togglefunction() }}>
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </div>
                        </InputAdornment>
                    ) : null,
                    style: {
                        borderRadius: "10px",
                        height: "45px",
                    },
                    sx: {
                        "& .MuiInputBase-input::placeholder": {
                            fontSize: "0.9rem",
                            color: theme.palette.grey.text,
                            opacity: 1,
                        },
                        "& .MuiInputBase-input": {
                            display: "flex",
                            alignItems: "center",
                            padding: "0 15px",
                            height: "100%",
                        },
                    },
                }}
            />
        </Box>
    );
};

export default OutlinedLabelledTextField;