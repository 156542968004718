import React from "react";
import {
  FaWifi,
  FaTv,
  FaRegLightbulb,
  FaSnowflake,
  FaChalkboard,
  FaMicrophoneAlt,
  FaMusic,
  FaChild,
} from "react-icons/fa";
import * as FaIcons from "react-icons/fa";
import { MdAir, MdOutlineScreenShare } from "react-icons/md";
import { GiPodium, GiTheaterCurtains } from "react-icons/gi";
import { RiReservedLine } from "react-icons/ri";
import { BiChair } from "react-icons/bi";
import { IoCheckmark } from "react-icons/io5";
import { HiOutlineSpeakerphone, HiOutlineLibrary } from "react-icons/hi";
import p1 from "../../assets/parking.svg";
import p2 from "../../assets/Valet.svg";

const featuresData = {
  facilities: [
    { icon: <FaRegLightbulb />, text: "Natural Light" },
    { icon: <FaWifi />, text: "Wifi" },
    { icon: <MdAir />, text: "Air conditioning" },
    { icon: <GiPodium />, text: "Podium" },
    { icon: <FaChalkboard />, text: "Whiteboard" },
    { icon: <FaTv />, text: "Flatscreen TV" },
    { icon: <MdOutlineScreenShare />, text: "Flipchart" },
    { icon: <RiReservedLine />, text: "Storage space" },
    { icon: <HiOutlineLibrary />, text: "Conference call facilities" },
  ],
  equipment: [
    { icon: <BiChair />, text: "Banquet chairs" },
    { icon: <FaChild />, text: "Cocktail tables" },
    { icon: <FaSnowflake />, text: "Stage deck" },
    { icon: <RiReservedLine />, text: "Storage space" },
    { icon: <GiTheaterCurtains />, text: "Dance floor" },
  ],
  audio: [
    {
      icon: <HiOutlineSpeakerphone />,
      text: "Indoor music allowed until 10:00PM",
      variable: "indoorMusicAllowed",
    },
    {
      icon: <HiOutlineSpeakerphone />,
      text: "Outdoor music allowed until 10:00PM",
      variable: "outdoorMusicAllowed",
    },
    {
      icon: <FaMusic />,
      text: "Clients can play their own music",
      variable: "clientsCanPlayOwnMusic",
    },
    {
      icon: <FaMicrophoneAlt />,
      text: "PA system / Speakers",
      variable: "paSystemSpeakers",
    },
    {
      icon: <FaMusic />,
      text: "Space has noise restrictions",
      variable: "spaceHasNoiseRestrictions",
    },
    {
      icon: <FaMusic />,
      text: "Clients can bring their own DJ",
      variable: "clientsCanBringOwnDJ",
    },
  ],
};

const Section = ({ title, items, isCheckIcon }) => (
  <div className="mb-6 flex flex-wrap w-full justify-between">
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <div className="grid lg:w-3/4 w-full grid-cols-1 sm:grid-cols-2 gap-4">
      {items.map((item, index) => (
        <div key={index} className="flex items-center">
          {isCheckIcon ? (
            <IoCheckmark className="text-green-500 mr-2" />
          ) : (
            <div className="text-2xl mr-2">{item.icon}</div>
          )}
          <span className="text-gray-700 capitalize">
            {isCheckIcon ? item : item.text}
          </span>
        </div>
      ))}
    </div>
  </div>
);

const Facilities = ({ data }) => {
  const providedFacilities = data?.facilities?.facilities || [];
  const providedEquipment = data?.equipment?.equipment || [];
  const providedAudio = data?.audio || {};

  const audioFeatures = [
    providedAudio.indoorMusicAllowed
      ? `Indoor music allowed until ${providedAudio.indoorMusicAllowed}`
      : null,
    providedAudio.outdoorMusicAllowed
      ? "Outdoor music allowed until 10:00PM"
      : null,
    providedAudio.clientsCanPlayOwnMusic
      ? "Clients can play their own music"
      : null,
    providedAudio.paSystemSpeakers ? "PA system / Speakers" : null,
    providedAudio.spaceHasNoiseRestrictions
      ? "Space has noise restrictions"
      : null,
    providedAudio.clientsCanBringOwnDJ ? "Clients can play their own DJ" : null,
  ].filter(Boolean);

  const filterFeatures = (featureSet, providedSet) => {
    // Ensure providedSet is an array
    const providedArray = Array.isArray(providedSet) ? providedSet : [];
    return featureSet?.filter(
      (feature) => !providedArray.some((provided) => provided === feature.text)
    );
  };

  const getAudioItems = () => {
    const items = [];
    if (providedAudio?.indoorMusicAllowed) {
      items.push({
        icon: <HiOutlineSpeakerphone />,
        text: `Indoor music allowed until ${providedAudio?.indoorMusicAllowedTime}`,
      });
    }
    if (providedAudio?.outdoorMusicAllowed) {
      items.push({
        icon: <HiOutlineSpeakerphone />,
        text: `Outdoor music allowed until ${providedAudio?.outdoorMusicAllowedTime}`,
      });
    }
    if (providedAudio?.clientsCanPlayOwnMusic) {
      items.push({
        icon: <FaMusic />,
        text: "Clients can play their own music",
      });
    }
    if (providedAudio?.paSystemSpeakers) {
      items.push({
        icon: <FaMicrophoneAlt />,
        text: "PA system / Speakers",
      });
    }
    if (providedAudio?.spaceHasNoiseRestrictions) {
      items.push({
        icon: <FaMusic />,
        text: "Space has noise restrictions",
      });
    }
    if (providedAudio?.clientsCanBringOwnDJ) {
      items.push({
        icon: <FaMusic />,
        text: "Clients can bring their own DJ",
      });
    }

    return items;
  };

  const getFacilities = () => {
    // console.log(
    //   "data in facilities",
    //   Object.keys(data?.facilities?.facilitiesFeatureIcons)?.length
    // );
    if (
      data &&
      data?.facilities?.facilitiesFeatureIcons &&
      Object.keys(data?.facilities?.facilitiesFeatureIcons)?.length > 0
    ) {
      const result = Object.keys(data?.facilities?.facilitiesFeatureIcons).map(
        (key) => {
          // Find if the key exists in defaultFacilities
          const defaultFacility = featuresData?.facilities?.find(
            (facility) =>
              facility.text === key && data?.facilities?.facilities[key]
          );
          let IconComponent = null;
          if (!defaultFacility) {
            IconComponent =
              FaIcons[data?.facilities?.facilitiesFeatureIcons[key]];
          }

          if (data?.facilities?.facilities[key]) {
            // Return an object with the icon and text
            return {
              icon: defaultFacility ? (
                defaultFacility.icon
              ) : IconComponent ? (
                <IconComponent />
              ) : null,
              text: key,
            };
          }
        }
      );

      console.log("result", result);

      return result?.filter((e) => e);
    }

    return [];
  };

  //   const filteredFacilities = filterFeatures(
  //     featuresData.facilities,
  //     providedFacilities
  //   ); // old code

  const filteredFacilities = getFacilities(); // new code

  // const filteredEquipment = filterFeatures(featuresData.equipment, providedEquipment);
  //   const filteredAudio = filterFeatures(featuresData.audio, audioFeatures); // old code
  const filteredAudio = getAudioItems(); // new code

  return (
    <div className="rounded-lg mb-8 lg:w-3/4 w-full px-5 space-y-5 mx-auto py-10">
      <Section title="Facilities" items={filteredFacilities} />
      {/* <Section title="Equipment" items={filteredEquipment} /> */}
      <Section
        title="Activities for kids"
        items={data?.facilities?.activitiesForKidsIncludes || []}
        isCheckIcon
      />
      <Section title="Audio" items={filteredAudio} />
      <div className="">
        <div className="flex justify-between mb-8">
          <div className="mb-6 flex flex-wrap w-full justify-between">
            <h2 className="text-xl font-semibold mb-4">Parking</h2>
            <div className="flex lg:w-3/4 w-full items-center space-x-4">
              {data?.location?.parkingAvailable?.parkingAvailable && (
                <div className="flex items-center space-x-1">
                  <img src={p1} alt="" className="w-6" />
                  <span>Parking available</span>
                </div>
              )}
              {data?.location?.parkingAvailable?.valetParking && (
                <div className="flex items-center space-x-1">
                  <img src={p2} alt="" className="w-6" />
                  <span>Valet Parking</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-6 flex flex-wrap w-full justify-between">
          <h2 className="text-xl font-semibold mb-4">Space Rules</h2>
          <div className="mb-4 lg:w-3/4 w-full">
            <div className="flex justify-between">
              <div>
                <h3 className="font-semibold">Age Policy</h3>
                <p>
                  {data?.spaceRules?.agePolicy
                    ? `${data.spaceRules.agePolicy.join("-")} years`
                    : "N/A"}
                </p>
              </div>
              <div>
                <h3 className="font-semibold">Ticketed events allowed?</h3>
                <p>{data?.spaceRules?.ticketedEventsAllowed ? "Yes" : "No"}</p>
              </div>
            </div>
            <div>
              <h3 className="font-semibold py-2">More space rules</h3>
              <ul className="list-disc list-inside">
                {data?.spaceRules?.moreSpaceRules ? (
                  data.spaceRules.moreSpaceRules
                    .split("\n")
                    .map((rule, index) => <li key={index}>{rule}</li>)
                ) : (
                  <li>No additional rules</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
