import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const OffersAndPackages = ({ data = [] }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const today = new Date(); // Get the current date

  // const filteredData = data?.filter((item) => {
  //   const endDate = new Date(item?.endDate); // Convert the end date string to a Date object
  //   return endDate >= today; // Keep only the items where the end date is in the future or today
  // });
  return (
    <div className="bg-white rounded-lg mb-8 lg:w-3/4 w-full px-5 mx-auto py-10">
      <h2 className="text-2xl font-bold mb-4">Offers & Packages</h2>
      {data &&
        data?.map((offer, index) => (
          <div key={index} className="mb-4">
            <div
              className="cursor-pointer bg-[#F7F7F7] p-4 rounded-lg flex justify-between items-center"
              onClick={() => toggleExpand(index)}
            >
              <h3 className="text-xl font-semibold">
                {offer.title || "No Title"}
              </h3>
              <span>
                {expandedIndex === index ? (
                  <MdKeyboardArrowUp className="text-3xl" />
                ) : (
                  <MdKeyboardArrowDown className="text-3xl" />
                )}
              </span>
            </div>
            {expandedIndex === index && (
              <div className="mt-4 p-4 border rounded-lg bg-[#F7F7F7]">
                <h3 className="text-lg font-semibold">
                  {offer.title || "No Title"}
                </h3>
                <div className="flex space-x-2 lg:w-1/2 w-full py-2 items-start">
                  {offer.startDate && (
                    <div className="mb-2 flex flex-col">
                      <strong>From:</strong> {offer.startDate}
                    </div>
                  )}
                  {offer.endDate && (
                    <div className="mb-2  flex flex-col">
                      <strong>To:</strong> {offer.endDate}
                    </div>
                  )}
                </div>
                {offer.description && (
                  <>
                    <p>Description</p>
                    <p>{offer.description}</p>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default OffersAndPackages;
