// GoogleMapComponent.jsx
import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import extractMapCoordinates from "../../utils/extractMapCoordinates";
import MapContainer from "../shared/map-container";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 0,
  lng: 0,
};

const GoogleMapComponent = ({ data }) => {
  const [mapLocation, setMapLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const address = `${data?.city}, ${data?.area}, ${data.country}`;
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  console.log("address", JSON.stringify(data.lat));
  console.log("address", JSON.stringify(data.lng));

  useEffect(() => {
    if (data?.lat && data?.lng) {
      setMapLocation({ lat: data?.lat, lng: data?.lng });
    } else if (data?.mapLink && data?.mapLink !== "") {
      const coords = extractMapCoordinates(data?.mapLink);
      if (coords) {
        setMapLocation(coords);
      } else {
        console.log("Invalid map link");
      }
    } else {
      if (
        data?.city &&
        data?.area &&
        data?.country &&
        typeof window !== "undefined"
      ) {
        const address = `${data.city}, ${data.area}, ${data.country}`;
        geocodeAddress(address);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setCenter({
      lat: data.lat,
      lng: data.lng,
    });
  }, [data]);

  // const [location, setLocation] = useState(center);

  // useEffect(() => {
  //     // Function to get latitude and longitude from address
  //     const fetchLocation = async () => {
  //         try {
  //             const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg`);
  //             const data = await response.json();
  //             console.log(data)
  //             if (data.results.length > 0) {
  //                 const { lat, lng } = data.results[0].geometry.location;
  //                 setLocation({ lat, lng });
  //             }
  //         } catch (error) {
  //             console.error('Error fetching location:', error);
  //         }
  //     };

  //     fetchLocation();
  // }, [address]);

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      console.log("geocode", results, status);
      if (status === "OK" && results[0]) {
        const location = results[0].geometry.location;
        const coords = {
          lat: location.lat(),
          lng: location.lng(),
        };
        setMapLocation(coords);
      } else {
        console.log(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  };

  return (
    <div className="py-5 lg:w-3/4 w-full px-5 border-t mx-auto">
      <h2 className="text-2xl font-bold mb-4">Where We Are Located</h2>
      {/* <LoadScript googleMapsApiKey="AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center} zoom={10}>
                    <MarkerF
                        position={center} />
                </GoogleMap>
            </LoadScript> */}
      <MapContainer
        isHotel={false}
        isVenue={true}
        lat={mapLocation.lat}
        lng={mapLocation.lng}
        mapLocation={mapLocation}
        setMapLocation={setMapLocation}
        draggable={false}
        showHeader={false}
      />
    </div>
  );
};

export default GoogleMapComponent;
