import React from 'react';

const Pricing = ({ data }) => {
    // Extract pricing data from props
    const basePrice = data?.basePrice || '';
    const mentionSeasons = data?.mentionSeasons || false;
    const highDemandMonths = data?.highDemandMonths || [];
    const midDemandMonths = data?.midDemandMonths || [];
    const lowDemandMonths = data?.lowDemandMonths || [];

    // Map months to season categories with colors
    const seasonColors = {
        high: 'text-red-500 border-red-500',
        mid: 'text-yellow-500 border-yellow-500',
        low: 'text-green-500 border-green-500',
    };

    // Combine all months into one array with color information
    const combinedSeasons = [
        ...highDemandMonths.map(month => ({ month, season: 'high', color: seasonColors.high })),
        ...midDemandMonths.map(month => ({ month, season: 'mid', color: seasonColors.mid })),
        ...lowDemandMonths.map(month => ({ month, season: 'low', color: seasonColors.low }))
    ];

    // Group seasons by their types
    const groupedSeasons = combinedSeasons.reduce((acc, { month, season, color }) => {
        if (!acc[season]) acc[season] = [];
        acc[season].push({ month, color });
        return acc;
    }, {});

    // Determine the season labels based on dynamic data
    const seasonLabels = {
        high: 'High Season',
        mid: 'Shoulder Season',
        low: 'Low Season'
    };

    return (
        <div className="py-5 lg:w-3/4 w-full px-5 border-t mx-auto">
            <div className="bg-white p-6 rounded-lg mb-8">
                <div className="flex justify-between flex-wrap items-center lg:w-1/2 mb-4">
                    <div className='flex items-center gap-1' >
                        <h2 className="text-2xl font-bold">Pricing</h2>
                        {/* <Tooltip text='Room Rate may vary during different seasons, submit your enquiries for a personalized quote for your booking' /> */}
                    </div>
                    <span className="text-xl">Starting From <span className="font-semibold">{basePrice} AED/ Room</span></span>
                </div>
                {mentionSeasons &&
                    <div className="flex justify-between flex-wrap items-start mb-4">
                        <h3 className="text-2xl font-bold mb-4">Seasonality</h3>
                        <div className="lg:w-3/4 w-full">
                            <div className="flex flex-wrap gap-2 mb-4">
                                {combinedSeasons.map((season, index) => (
                                    <div key={index} className={`flex items-center justify-center w-16 h-10 border rounded-full mx-1 ${season.color}`}>
                                        <span>{season.month}</span>
                                    </div>
                                ))}
                            </div>

                            <div className="flex justify-between">
                                {Object.entries(groupedSeasons).map(([seasonType, months]) => (
                                    <div key={seasonType} className="flex items-start">
                                        <span className={`w-4 h-4 mt-2 rounded-full inline-block mr-2 ${seasonType === 'high' ? 'bg-red-500' : seasonType === 'mid' ? 'bg-yellow-500' : 'bg-green-500'}`}></span>
                                        <div>
                                            <p className="font-semibold">{seasonLabels[seasonType]}</p>
                                            <div className="flex flex-wrap gap-2 items-center">
                                                {months.map(({ month }, idx) => (
                                                    <p key={idx} className="text-gray-500">{month}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    );
};

export default Pricing;
