import React, { useContext } from 'react';
import { FaPhone, FaConciergeBell, FaLuggageCart, FaBed, FaBusinessTime, FaMicrophone, FaSwimmingPool, FaSpa, FaShuttleVan, FaTaxi, FaParking } from 'react-icons/fa';
import { HotelContext } from '../../context/HotelContext';
import * as FaIcons from "react-icons/fa";


const featuresData = [
    {
        category: 'Room features',
        key: 'roomFeatures',
        items: [
            {
                name: "Calls (Local)",
                icon: <img src="/room-features/call.svg" />,
                isDefault: true,
            },
            {
                name: "Calls (Toll-free)",
                icon: <img src="/room-features/toll-free.svg" />,
                isDefault: true,
            },
            {
                name: "Concierge Services",
                icon: <img src="/room-features/concierge.svg" />,
                isDefault: true,
            },
            {
                name: "Laundry Service",
                icon: <img src="/room-features/laundry.svg" />,
                isDefault: true,
            },
            {
                name: "Luggage Storage",
                icon: <img src="/room-features/luggage.svg" />,
                isDefault: true,
            },
            {
                name: "Internet Access",
                icon: <img src="/room-features/internet.svg" />,
                isDefault: true,
            },
            {
                name: "Room Service",
                icon: <img src="/room-features/food-tray.svg" />,
                isDefault: true,
            },
            {
                name: "Air conditioning",
                icon: <img src="/room-features/air-condition.svg" />,
                isDefault: true,
            },
            {
                name: "Kitchen",
                icon: <img src="/room-features/kitchen.svg" />,
                isDefault: true,
            },
        ]
        //  [
        //     { name: 'Calls (Local)', icon: FaPhone, key: 'callsLocal' },
        //     { name: 'Concierge Services', icon: FaConciergeBell, key: 'conciergeServices' },
        //     { name: 'Luggage Storage', icon: FaLuggageCart, key: 'luggageStorage' },
        //     { name: 'Room Service', icon: FaBed, key: 'roomService' },
        //     { name: 'Calls (Toll-free)', icon: FaPhone, key: 'callsTollFree' },
        //     { name: 'Laundry Service', icon: FaConciergeBell, key: 'laundryService' },
        //     { name: 'Internet Access', icon: FaLuggageCart, key: 'internetAccess' },
        //     { name: 'Kitchen', icon: FaBed, key: 'kitchen' },
        // ],
    },
    {
        category: 'Business features',
        key: 'businessFeatures',
        items: [
            {
                name: "Business Centre",
                icon: <img src="/business-features/business.svg" />,
            },
            {
                name: "Video Conference",
                icon: <img src="/business-features/video-conference.svg" />,
            },
            { name: "VIP Services", icon: <img src="/business-features/vip.svg" /> },
            {
                name: "AV Equipment",
                icon: <img src="/business-features/av-equipements.svg" />,
            },
            {
                name: "Onsite AV Staff",
                icon: <img src="/business-features/support.svg" />,
            },
            {
                name: "High Speed Internet (100 Mbps)",
                icon: <img src="/business-features/internet.svg" />,
            },
        ]
        // [
        //     { name: 'Business Centre', icon: FaBusinessTime, key: 'businessCentre' },
        //     { name: 'VIP Services', icon: FaMicrophone, key: 'vipServices' },
        //     { name: 'AV Equipment', icon: FaMicrophone, key: 'avEquipment' },
        //     { name: 'High Speed Internet (100 Mbps)', icon: FaMicrophone, key: 'highSpeedInternet' },
        //     { name: 'Onsite AV Staff', icon: FaMicrophone, key: 'onsiteAvStaff' },
        //     { name: 'Video Conference', icon: FaMicrophone, key: 'videoConference' },
        // ],
    },
    {
        category: 'Recreational features',
        key: 'recreationalFeatures',
        items: [
            { name: "Health Club", icon: <img src="/features/medical.svg" /> },
            { name: "Indoor Pool", icon: <img src="/features/pool.svg" /> },
            { name: "Outdoor Pool", icon: <img src="/features/outdoor-pool.svg" /> },
            { name: "Spa", icon: <img src="/features/massage.svg" /> },
            { name: "Salon", icon: <img src="/features/scissors.svg" /> },
            { name: "Tennis Courts", icon: <img src="/features/sports.svg" /> },
            { name: "Whirlpool", icon: <img src="/features/whirlpool.svg" /> },
        ]
        // [
        //     { name: 'Health Club', icon: FaSwimmingPool, key: 'healthClub' },
        //     { name: 'Indoor Pool', icon: FaSwimmingPool, key: 'indoorPool' },
        //     { name: 'Outdoor Pool', icon: FaSwimmingPool, key: 'outdoorPool' },
        //     { name: 'Spa', icon: FaSpa, key: 'spa' },
        //     { name: 'Salon', icon: FaSpa, key: 'salon' },
        //     { name: 'Tennis Courts', icon: FaSpa, key: 'tennisCourts' },
        //     { name: 'Whirlpool', icon: FaSpa, key: 'whirlpool' },
        // ],
    },

];
const locationData = [

    {
        category: 'Accessibility',
        key: 'accessibilityFeatures',
        items: [
            { name: 'Airport Shuttle', icon: FaShuttleVan, key: 'airportShuttle' },
            { name: 'Bus', icon: FaShuttleVan, key: 'bus' },
            { name: 'Taxi', icon: FaTaxi, key: 'taxi' },
            { name: 'Metro', icon: FaTaxi, key: 'metro' },
        ],
    },
    {
        category: 'Parking',
        key: 'parkingFeatures',
        items: [
            { name: 'Parking available', icon: FaParking, key: 'parkingAvailable' },
            { name: 'Valet Parking', icon: FaParking, key: 'valetParking' },
        ],
    },
];
const renderIcon = (iconName) => {
    const IconComponent = FaIcons[iconName];
    return IconComponent ? <IconComponent size={32} /> : null;
};

const FeatureItem = ({ feature, available }) => {
    const IconComponent = feature.icon;
    return (
        <div className={`flex items-center gap-4 mb-2 ${available ? 'block' : 'hidden'}`}>
            {/* <IconComponent className="text-xl mr-2" /> */}
            {feature.icon}
            <span>{feature.name}</span>
        </div>
    );
};
const FeatureItem1 = ({ feature, available }) => {
    const IconComponent = feature.icon;
    return (
        <div className={`flex items-center gap-4 mb-2 ${available ? 'block' : 'hidden'}`}>
            <IconComponent className="text-3xl mr-2" />
            <span>{feature.name}</span>
        </div>
    );
};


const FeaturesSection = () => {

    const { formData } = useContext(HotelContext);

    Object.keys(formData?.amenities || {}).forEach((categoryKey) => {
        const category = featuresData.find(cat => cat.key === categoryKey);
        // if (category) {
        //     Object.keys(formData.amenities[categoryKey]).forEach((featureKey) => {
        //         if (!category.items.find(item => item.key === featureKey)) {
        //             category.items.push({
        //                 name: featureKey,
        //                 icon: FaIcons.FaInfoCircle,
        //                 key: featureKey
        //             });
        //         }
        //     });
        // }
    });
    return (
        <div className="py-5 lg:w-3/4  w-full px-5 border-t mx-auto">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-2xl gap-5 font-bold mb-6">What this place offers</h2>
                {featuresData.map((category, index) => (
                    <div key={index} className="mb-6 flex gap-5 items-start flex-wrap">
                        <h3 className="text-xl font-bold mb-4 lg:w-1/4 w-full">{category.category}</h3>
                        <div className="grid grid-cols-2 lg:w-3/5 justify-between">
                            {category.items.map((feature, itemIndex) => {
                                let available = false;
                                if (category.key === 'roomFeatures') {
                                    available = formData?.amenities?.roomFeatures?.[feature.name] || false;
                                } else if (category.key === 'businessFeatures') {
                                    available = formData?.amenities?.businessFeatures?.[feature.name] || false;
                                } else if (category.key === 'recreationalFeatures') {
                                    available = formData?.amenities?.recreationalFeatures?.[feature.name] || false;
                                }
                                return (
                                    <FeatureItem
                                        key={itemIndex}
                                        feature={feature}
                                        available={available}
                                    />
                                );
                            })}

                        </div>
                    </div>
                ))}

                {locationData.map((category, index) => (
                    <div key={index} className="mb-6 flex gap-5 items-start flex-wrap">
                        <h3 className="text-xl font-bold mb-4 lg:w-1/4 w-full">{category.category}</h3>
                        <div className="grid grid-cols-2 lg:w-3/5 gap-4">
                            {category.items.map((feature, itemIndex) => {
                                let available = false;
                                if (category.key === 'accessibilityFeatures') {
                                    available = formData?.overview?.locationDetails?.accessibilityFeatures?.[feature.key] || false;
                                } else if (category.key === 'parkingFeatures') {
                                    available = formData?.overview?.locationDetails?.[feature.key] || false;
                                }

                                return (
                                    <FeatureItem1
                                        key={itemIndex}
                                        feature={feature}
                                        available={available}
                                    />
                                );
                            })}

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeaturesSection;
