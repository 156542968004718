import axios from 'axios';
import { base_URL } from '../config/config';
const API_BASE_URL = `${base_URL}/api`;

export const getVenues = async (page = 3, limit = 10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/venueform/getAllVenueForms`, {
            params: { page, limit }
        });
        return response.data.forms;
    } catch (error) {
        console.error('Error fetching venue forms:', error);
        throw error;
    }
};


export const deleteItem = async ({ category, id }) => {
    const response = await axios.delete(`${API_BASE_URL}/venueform/deleteItem/${category}/${id}`);
    return response.data;
};

export const getSingleVenue = async ({ category, id }) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/venueform/getSingleVeune/${category}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching onboarding details:', error);
    }
};


export const approveItem = async ({ category, id, email, name }) => {
    const response = await axios.post(`${API_BASE_URL}/venueform/getApproveItem/${category}/${id}/${email}/${name}`);
    return response.data;
};

export const rejectItem = async ({ category, id, reasons, email, name }) => {
    const response = await axios.post(`${API_BASE_URL}/venueform/getRejectItem/${category}/${id}`, { reasons, email, name });
    return response.data;
};


export const searchAndFilterVenues = async ({ searchQuery = '', filter = '', page = 1, limit = 10 }) => {
    try {

        const response = await axios.get(`${API_BASE_URL}/venueform/searchAndFilterVenues`, {
            params: { searchQuery, filter, page, limit }
        });
        return response.data.venues;
    } catch (error) {
        console.error('Error searching and filtering venues:', error);
        throw error;
    }
};