/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import backgroundIcon from "../assets/images/background_icon.svg";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OutlinedLabelledTextField from "../components/shared/outlinedTextField";
import { login } from "../api/login.request";
// import { fetchLoggedUser } from "../../api/loggedUser";

function Login() {
  const [user, setUser] = useState();
  console.log('user', user)

  // React.useEffect(() => {
  //   const fetchUser = async () => {
  //     try {
  //       const response = await fetchLoggedUser();
  //       console.log(response.vendor)
  //       setUser(response.vendor)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   fetchUser();
  // }, [])

  const theme = useTheme();
  const [workMail, setWorkMail] = useState("");
  const [createPassword, setCreatePassword] = useState("");
  const [error, setError] = useState(""); // New state for error message
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const loginData = {
        email: workMail,
        password: createPassword,
      };

      const response = await login(loginData);
      console.log("response", response)
      localStorage.setItem("accessToken", response.accessToken);
      localStorage.setItem("adminId", response.vendor.id);
      console.log(response);
      navigate('/venue-table')

      // Navigate to dashboard
      // user?.onBoardingDetails?.payment ? navigate('/dashboard') : navigate('/onboarding')
    } catch (error) {

      if (error.response && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again.");
      }
      console.error("Error logging in:", error);
      toast.error(error.message);
    }
  };

  const isFormValid = () => {
    return workMail.trim() !== "" && createPassword.trim() !== "";
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundIcon})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          minHeight: "75vh",
        }}
      >
        <Container>
          <Box sx={{ mt: 6, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                mt: { xs: 7, md: 10 },
                fontWeight: "bold",
                fontSize: { xs: "1.6rem" },
              }}
            >
              Log in as{" "}
              <span className="text-primary">
                Admin
              </span>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ mb: { md: 4 }, color: "grey.text" }}
            >
              Welcome back! Log in and continue your journey.
            </Typography>
          </Box>
        </Container>
        <Container
          maxWidth="sm"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box sx={{ mt: 7, width: { xs: "95%", md: "80%" } }}>
            <form onSubmit={handleSubmit}>
              <OutlinedLabelledTextField
                id="workMail"
                label="Work Email"
                placeholder="user@workapp.com"
                type="email"
                value={workMail}
                onChange={(e) => setWorkMail(e.target.value)}
                error={!!error} // Set error state for workMail field
                helperText={error && error.includes("Vendor") ? error : ""} // Display error if it contains "Vendor"
              />

              <OutlinedLabelledTextField
                id="createPassword"
                label="Password"
                placeholder="Enter your password"
                type={showPassword ? 'text' : 'password'}
                value={createPassword}
                onChange={(e) => setCreatePassword(e.target.value)}
                error={!!error} // Set error state for createPassword field
                helperText={error && !error.includes("Vendor") ? error : ""} // Display non-"Vendor" error message\
                eye={true}
                showPassword={showPassword}
                togglefunction={togglePasswordVisibility}
              />

              {/* <Box sx={{ mb: 2, textAlign: "right" }}>
                <Link
                  href="/reset"
                  color="primary"
                  sx={{ textDecoration: "none", fontWeight: "500" }}
                >
                  Forgot Password?
                </Link>
              </Box> */}

              <Button
                type="submit"
                variant="contained"
                fullWidth
                className={`${isFormValid()
                  ? "!bg-primary"
                  : "grey.border"}`}
                size="large"
                sx={{
                  mb: { xs: 1, md: 3 },
                  borderRadius: "8px",
                  mt: 2,
                  // backgroundColor: isFormValid()
                  //   ? "primary.main"
                  //   : "grey.border",
                  color: isFormValid() ? "white" : "text",
                }}
                disabled={!isFormValid()}
              >
                Log In
              </Button>
            </form>
            {/* 
            <Typography
              variant="body2"
              sx={{
                fontSize: "1rem",
                color: "grey.text",
              }}
            >
              Don't have an account?{" "}
              <Link
                href="/register"
                color="primary"
                sx={{ textDecoration: "none", fontWeight: "500" }}
              >
                Register
              </Link>
            </Typography> */}
          </Box>
        </Container>
      </div>
    </>
  );
}

export default Login;
