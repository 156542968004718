/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  FaStar,
  FaMapMarkerAlt,
  FaHome,
  FaBed,
  FaHotel,
  FaLeaf,
  FaRegMap,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  OverviewIcon1,
  OverviewIcon2,
  OverviewIcon3,
  OverviewIcon4,
} from "../../svgs";

const OverviewSection = ({ data }) => {
  // Overview details pulled dynamically from data
  const overviewDetails = [
    {
      icon: <OverviewIcon1 />,
      label: "Starts From",
      value: `${data?.pricing?.basePrice || "Price Missing"} per Night`,
    },
    {
      icon: <OverviewIcon2 />,
      label: "Rooms",
      value: data?.accommodation?.accommodations || "Rooms Missing",
    },
    {
      icon: <OverviewIcon3 />,
      label: "Venues",
      value: data?.venues?.length || "Venues Missing",
    },
    {
      icon: <OverviewIcon4 />,
      label: "Sustainable",
      value: data?.overview?.additionalDetails?.isSustainable ? "Yes" : "No",
    },
  ];

  return (
    <>
      <div className="lg:w-3/4 w-full px-5 mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-3xl font-bold flex gap-4 items-center">
            {data?.overview?.basicDetails?.hotelName || "Hotel Name Missing"}
            <span className="flex gap-2 items-center text-sm text-[#F2C200]">
              {data?.overview.basicDetails?.hotelRating ? (
                Array.from(
                  { length: data?.overview?.basicDetails?.hotelRating },
                  (_, i) => <FaStar key={i} />
                )
              ) : (
                <FaStar className="text-gray-400" />
              )}
            </span>
          </h2>
        </div>

        <div className="text-gray-600 flex gap-2 items-center mb-4">
          <FaMapMarkerAlt className="inline mr-2" />
          <span className="font-medium underline decoration-2 underline-offset-2">
            {data?.overview?.locationDetails?.city || "Location Missing"}
          </span>
          <a
            href="#map"
            className="text-blue-500 flex ml-1 font-semibold gap-2 items-center"
          >
            <FaRegMap />{" "}
            <span className="underline underline-offset-2 decoration-2">
              Show on map
            </span>
          </a>
        </div>

        <h3 className="text-2xl font-bold py-4">Overview</h3>

        <div className="grid lg:grid-cols-4 grid-cols-2 gap-8 mb-4">
          {overviewDetails.map((detail, index) => (
            <div
              key={index}
              className="flex flex-col gap-2 w-full text-black items-center bg-[#F7F7F7] p-4 rounded-lg shadow-md"
            >
              {detail.icon}
              <p>{detail.label}</p>
              <p className="text-lg font-semibold">{detail.value}</p>
            </div>
          ))}
        </div>

        <div className="h-[1px] bg-[#EBEBEB] w-full my-10"></div>

        <h3 className="text-2xl font-bold mb-4">Highlights</h3>
        <div className="flex justify-between p-2 rounded-lg bg-[#FFEBEB]">
          <div className="p-4 rounded-lg w-1/2 mr-2">
            <h4 className="font-semibold mb-2">View</h4>
            <p>
              {data?.overview?.basicDetails?.selectedTags?.views.join(", ") ||
                "Views Missing"}
            </p>
          </div>
          <div className="p-4 border-l border-gray-300 pl-5 w-1/2 ml-2">
            <h4 className="font-semibold mb-2">Style</h4>
            <p>
              {Object.entries(
                data?.overview?.basicDetails?.selectedTags?.styles || {}
              )
                .flatMap(([key, values]) => values)
                .join(", ") || "Styles Missing"}
            </p>
          </div>
        </div>

        <div className="flex justify-between mt-5 p-2 rounded-lg bg-[#FFEBEB]">
          <div className="p-4 rounded-lg w-1/3 mr-2">
            <h4 className="font-semibold mb-2">Build on</h4>
            <p>
              {data?.overview?.additionalDetails?.hotelBuiltYear || " Missing"}
            </p>
          </div>
          <div className="p-4 border-l border-gray-300 pl-5 w-1/3 ml-2">
            <h4 className="font-semibold mb-2">Hotel Chain</h4>
            <p>{data?.overview?.basicDetails?.hotelChain || " Missing"}</p>
          </div>
          <div className="p-4 border-l border-gray-300 pl-5 w-1/3 ml-2">
            <h4 className="font-semibold mb-2">Hotel Brand</h4>
            <p>{data?.overview?.basicDetails?.hotelBrand || " Missing"}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewSection;
