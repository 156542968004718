import React from 'react';
import { FaWineGlassAlt, FaCocktail, FaGlassCheers, FaUtensils, FaShuttleVan } from 'react-icons/fa';
import { GiWineBottle } from 'react-icons/gi';
import { IoCheckmark } from 'react-icons/io5';
import { FiRefreshCcw } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const WhatThisPlaceOffers = ({ data }) => {
    // Extract relevant data
    const offers = [
        { icon: <FaUtensils />, text: 'Offers catering', condition: data?.cateringAndDrinks?.venueProvideInHouseCatering },
        { icon: <FaShuttleVan />, text: 'Outside catering allowed', condition: data?.cateringAndDrinks?.externalCatering },
        { icon: <GiWineBottle />, text: 'Bring your own alcohol Allowed', condition: data?.cateringAndDrinks?.bringYourOwnAlcoholAllowed },
        { icon: <FaCocktail />, text: 'Corkage fee for bring your own alcohol', condition: data?.cateringAndDrinks?.corkageFeeForBringYourOwnAlcohol },
        { icon: <FaGlassCheers />, text: 'Alcohol/ Liquor License until 10:00 PM', condition: data?.cateringAndDrinks?.alcoholLiquorLicense },
        { icon: <FaWineGlassAlt />, text: 'Shisha', condition: data?.cateringAndDrinks?.shisha },
        { icon: <FiRefreshCcw />, text: 'Refreshments', condition: data?.cateringAndDrinks?.refreshments },
    ].filter(offer => offer.condition);

    const menuItems = data?.cateringAndDrinks?.availableMenu || [];
    const refreshments = data?.cateringAndDrinks?.refreshmentsForGuests ? ['Tea & Coffee', 'Coffee', 'Water'] : [];
    const servingStyles = data?.cateringAndDrinks?.servingStyle || [];
    const cuisineOptions = data?.cateringAndDrinks?.cuisine || [];

    return (
        <div className="rounded-lg mb-8 lg:w-3/4 w-full px-5 mx-auto">
            <h2 className="text-2xl font-bold mb-4">What This Place Offers</h2>

            <div className="mb-6 flex justify-between flex-wrap gap-5">
                <h3 className="text-xl lg:w-1/5 w-full font-semibold mb-2">Catering & Drinks</h3>
                <div className="lg:w-3/4 w-full">
                    {offers.length > 0 && (
                        <div className="mb-6">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {offers.map((offer, index) => (
                                    <div key={index} className="flex items-center">
                                        <div className="text-2xl mr-2">{offer.icon}</div>
                                        <span className="text-gray-700">{offer.text}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <h3 className="text-xl flex flex-col lg:w-1/5 w-full font-semibold mb-2">
                    Menu
                    <Link to="#" className="text-red-500 mb-2 text-sm font-normal inline-block">View Menu</Link>
                </h3>
                <div className="lg:w-3/4 w-full">
                    {menuItems.length > 0 && (
                        <div className="mb-6">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                {menuItems.map((item, index) => (
                                    <div key={index} className="flex items-center">
                                        <IoCheckmark className="text-green-500 mr-2" />
                                        <span className="text-gray-700">{item}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {refreshments.length > 0 && (
                        <div className="w-full">
                            <h3 className="text-lg lg:w-1/5 w-full font-medium py-3 mb-2">Refreshments</h3>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                {refreshments.map((item, index) => (
                                    <div key={index} className="flex items-center">
                                        <IoCheckmark className="text-green-500 mr-2" />
                                        <span className="text-gray-700">{item}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {servingStyles.length > 0 && (
                        <div className="w-full">
                            <h3 className="text-lg lg:w-1/5 w-full font-medium py-3 mb-2">Serving style</h3>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                {servingStyles.map((style, index) => (
                                    <div key={index} className="flex items-center">
                                        <IoCheckmark className="text-green-500 mr-2" />
                                        <span className="text-gray-700">{style}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {cuisineOptions.length > 0 && (
                        <div className="w-full">
                            <h3 className="text-lg lg:w-1/5 w-full font-medium py-3 mb-2">Cuisine type</h3>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                {cuisineOptions.map((cuisine, index) => (
                                    <div key={index} className="flex items-center">
                                        <IoCheckmark className="text-green-500 mr-2" />
                                        <span className="text-gray-700">{cuisine}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WhatThisPlaceOffers;
