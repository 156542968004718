import React, { useState } from 'react';

const AboutThisHotel = ({ data }) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <div className="py-5 lg:w-3/4 w-full  px-5 mx-auto">

            <div className="bg-white p-6 rounded-lg mb-8">
                <h2 className="text-2xl font-bold mb-4">About This Venue</h2>
                <p className={`text-[#222222] text-xl font-normal mb-4 ${showMore ? '' : 'line-clamp-3'}`}>
                    {data?.venueDescription}
                </p>
                <button
                    onClick={() => setShowMore(!showMore)}
                    className="text-primary font-medium"
                >
                    {showMore ? 'Show Less' : 'Show More'}
                </button>
            </div>


        </div>
    );
};

export default AboutThisHotel;
